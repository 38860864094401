import styled from 'styled-components';

export const SearchStyled = styled.form`
  display: none;
  text-align: right;
  /* margin: 1rem 2rem 1rem 1rem; */

  a.closeSearch {
    display: none;
  }

  input {
    padding: 0.25rem;
    border: 0;
    background: #101010;
    color: #e0e0e0;
    &::placeholder {
      color: rgba(242, 19, 104, 1);
    }
  }

  &.expanded {
    box-shadow: 0 0 3rem rgba(16, 16, 16, 0.8);
    background: rgba(16, 16, 16, 1);
    border: 1px solid rgba(242, 19, 104, 1);
    border-radius: 0.5rem;
    margin: 4rem;
    width: calc(100% - 8rem);
    left: 0;
    top: 0;
    color: #e0e0e0;
    position: absolute;

    a {
      color: rgba(242, 19, 104, 1);
      &:hover {
        color: #e0e0e0;
      }
    }

    a.closeSearch {
      display: block;
      margin: 2rem;
      border-radius: 0.5rem;
      background: #e0e0e0;
      float: right;
      width: 2.5rem;
      height: 2.5rem;
      text-align: center;
      line-height: 1.4;
      color: #101010;

      &:hover {
        background: rgba(242, 19, 104, 1);
        color: #101010;
      }
    }

    input {
      display: none;
      /* margin: 2rem;
      width: 80%; */
    }

    & > section {
      text-align: left;
      margin: 2rem 4rem 4rem;

      ul {
        list-style-type: none;
        margin: 2rem;
      }

      li {
        a {
          padding: 0.75rem;
          display: block;
          /* color: #e0e0e0; */

          color: rgba(242, 19, 104, 1);

          &:hover {
            /* color: #101010; */
            color: #e0e0e0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 640px) and (min-width: 381px) {
    display: none;
  }

  @media only screen and (max-width: 380px) {
    display: none;
  }

  /* @media only screen and (max-width: 540px) and (min-width: 321px) {
    width: 100%;
    margin: 1rem 0;

    input {
      margin: 0;
      padding: 0.5rem;
      width: 100%;
    }

    &.expanded {
      width: calc(100% - 4rem);
      margin: 2rem;

      a {
        margin-right: 0;
      }
    }
  } */
`;
