import React from 'react';
import { string } from 'prop-types';
import { Social } from './social-list.style';

export const SocialList = ({ email, twitter }) => (
  <Social>
    <li>
      <a href={`mailto:${email}`}>
        <meta itemProp="email" content={`${email}`} />
        <svg
          aria-hidden="true"
          data-prefix="fas"
          data-icon="envelope-square"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          data-fa-i2svg=""
          aria-labelledby="emailIcon"
          aria-describedby="emailIconDescription"
        >
          <title id="emailIconTitle">email icon</title>
          <desc id="emailIconDescription">icon shaped like a letter envelope, send email to {email}</desc>
          <path
            fill="currentColor"
            d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM178.117 262.104C87.429 196.287 88.353 196.121 64 177.167V152c0-13.255 10.745-24 24-24h272c13.255 0 24 10.745 24 24v25.167c-24.371 18.969-23.434 19.124-114.117 84.938-10.5 7.655-31.392 26.12-45.883 25.894-14.503.218-35.367-18.227-45.883-25.895zM384 217.775V360c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V217.775c13.958 10.794 33.329 25.236 95.303 70.214 14.162 10.341 37.975 32.145 64.694 32.01 26.887.134 51.037-22.041 64.72-32.025 61.958-44.965 81.325-59.406 95.283-70.199z"
          />
        </svg>
        Email
      </a>
    </li>
    <li>
      <a href={`https://twitter.com/${twitter}`} itemProp="sameAs">
        <svg
          aria-hidden="true"
          data-prefix="fab"
          data-icon="twitter-square"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          data-fa-i2svg=""
          aria-labelledby="twitterLogoTitle"
        >
          <title id="twitterLogoTitle">twitter logo</title>
          <desc id="twitterLogoDescription">small, blue, stylised bird</desc>
          <path
            fill="currentColor"
            d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"
          />
        </svg>
        Twitter
      </a>
    </li>
  </Social>
);

SocialList.propTypes = {
  email: string.isRequired,
  twitter: string.isRequired,
};
