import styled from 'styled-components';

export const SiteHeaderStyled = styled.header`
  /* box-shadow: inset 0 0 0 1000px rgba(130, 11, 56, 0.1); */
  box-shadow: inset 0 0 0 1000px rgba(19, 190, 242, 0.1);
  color: #e0e0e0;
  border-bottom: 1px solid rgba(19, 190, 242, 0.2);

  & > section {
    margin: 0 auto -1px;
    padding: 1rem 2rem 0;
    max-width: 96rem;

    display: flex;
    flex-flow: column;

    & > div:first-child {
      flex-basis: 1 0 100%;
    }
  }

  @media only screen and (max-width: 640px) and (min-width: 381px) {
  }

  @media only screen and (max-width: 380px) {
  }

  /* @media only screen and (max-width: 540px) and (min-width: 321px) {
    & > section {
      flex: 1 1 100%;
      flex-flow: column;

      position: relative;
    }
  } */
`;

export const Additional = styled.div`
  @media only screen and (max-width: 640px) and (min-width: 381px) {
  }

  @media only screen and (max-width: 380px) {
  }
`;
