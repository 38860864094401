import styled from 'styled-components';

export const TagListStyled = styled.ul`
  &,
  ul {
    list-style-type: none;
  }

  & ul {
    margin: 1rem 2rem;
  }

  li {
    a {
      color: white;
    }
  }

  &.list {
    & > li {
      margin-bottom: 5rem;
    }
    a {
      text-decoration: none;
    }
    & > li {
      & a {
        color: #e0e0e0;
        &:hover {
          color: rgba(242, 19, 104, 1);
          text-decoration: underline;
        }
      }
      li {
        margin-bottom: 3rem;
        a {
          color: rgba(242, 19, 104, 1);

          &:hover {
            color: #e0e0e0;
          }
        }
      }

      h2 {
        text-transform: capitalize;
      }
    }
  }

  &.wrapped {
    overflow: hidden;
    display: block;

    li {
      float: left;
      display: block;
    }

    a {
      text-decoration: none;
      /* background: #101010; */
      padding: 1rem;
      margin: 0.5rem 1rem;
      margin-left: 0;
      border-radius: 0.5rem;
      display: block;

      &:hover,
      &:active {
        /* text-decoration: underline; */
        background: #e0e0e0;
        color: #101010;
      }

      h2 {
        font-size: 1.6rem;
        font-weight: normal;
      }
    }
  }
`;
