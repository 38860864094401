import React from 'react';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';
import { arrayOf, object, string, oneOfType, node, bool } from 'prop-types';
import { NavStyled } from './navMenu.styles';

// TODO : pass in as props sourced from config
// const navItems = [{ label: 'blog', url: '/' }, { label: 'books', url: '/books' }, { label: 'about me', url: '/about' }];
const navItems = [
  { label: 'blog', url: '/' },
  { label: 'series', url: '/series/' },
  { label: 'about me', url: '/about/' },
];

const nonBlogPaths = navItems.slice(1, navItems.length).map(navItem => navItem.url);

export function NavMenu({ extraClassName }) {
  return (
    <NavStyled className={extraClassName}>
      <NavLinks items={navItems} isResponsive={true} />
    </NavStyled>
  );
}

NavMenu.propTypes = {
  extraClassName: string,
  children: oneOfType([node, arrayOf(node)]),
};

function NavLinks({ items }) {
  return (
    <ul>
      {items.map(navItem => {
        return (
          <li key={`${kebabCase(navItem.label)}`}>
            <Link getProps={props => checkIsActive({ ...props, navItem })} to={navItem.url}>
              {navItem.label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

function checkIsActive({ className, location, navItem }) {
  let active = false;
  if (navItem.url === location.pathname) {
    active = true;
  } else {
    active = nonBlogPaths.indexOf(location.pathname) === -1 && nonBlogPaths.indexOf(navItem.url) === -1;
  }
  return {
    className: `${className || ''}${active ? ' active' : ''}`.trim(),
  };
}

NavLinks.propTypes = {
  items: arrayOf(object).isRequired,
  renderingFullNav: bool,
};
