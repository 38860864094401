import styled from 'styled-components';
import stripes from '../images/dark_stripes.png';

export const NavStyled = styled.nav`
  flex: 1 1 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* margin-top: 3rem; */

  position: relative;

  & > ul {
    list-style-type: none;
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    margin: 0;

    & > li {
      color: #e0e0e0;
      margin: 0;

      & > a {
        color: #e0e0e0;
        display: block;
        padding: 1rem 2rem;
        text-decoration: none;

        &:hover {
          background: rgba(16, 16, 16, 0.6);
          color: rgba(242, 19, 104, 1);
        }

        &.active {
          background-image: url(${stripes});
          color: rgba(242, 19, 104, 1);
          /* border: 1px solid rgba(242, 19, 104, 0.2); */
          border: 1px solid rgba(19, 190, 242, 0.2);
          border-bottom: 0;
          border-radius: 0.6rem 0.6rem 0 0;
          font-weight: bold;
        }
      }

      &.active {
        a {
          background: #101010;
        }
      }

      &.navChild1 {
        background: black;
        ul {
          position: absolute;
          top: 2rem;
          right: 0;
        }
      }

      &.navChild2 {
        /* background: green; */
        input {
          position: absolute;
          top: 0;
          right: 0;
          /* background: cyan; */
        }
      }
    }
  }

  li.fullNav {
    display: none;

    .fullNavList {
      transition: max-height 0.5s ease-in-out;
      max-height: 0px;
      overflow: hidden;
    }

    .fullNavToggle {
      display: none;
    }

    .fullNavToggle:checked + .fullNavCTA + .fullNavList {
      max-height: 350px;
    }
  }

  /* @media only screen and (max-width: 640px) and (min-width: 381px) {
    & > ul > li {
      &:nth-child(2) {
        display: none;
      }
    }

    li.fullNav {
      display: block;

      color: #101010;
      padding: 1rem 2rem;
      text-decoration: none;

      &:hover {
        background: rgba(16, 16, 16, 0.6);
        color: #e0e0e0;
      }
    }
  } */

  @media only screen and (max-width: 380px) {
  }
`;
