import styled from 'styled-components';

export const Social = styled.ul`
  /* background: rgba(0, 255, 255, 0.2); */

  display: flex;
  flex-flow: row;
  list-style-type: none;
  padding: 2rem 0 0;

  & > li {
    text-align: right;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }

    a,
    span {
      text-align: center;
      line-height: 1.5;
      color: #e0e0e0;
    }

    a {
      padding: 1rem 2rem 1rem 1rem;
      text-decoration: none;

      svg {
        width: 1.5rem;
        margin: 0.2rem 0 0 1rem;
        float: left;
        color: rgba(242, 19, 104, 1);
      }

      &:hover {
        color: rgba(242, 19, 104, 1);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    justify-content: center;
  }

  /* @media only screen and (max-width: 640px) and (min-width: 381px) {
  }

  @media only screen and (max-width: 380px) {
  } */

  /* @media only screen and (max-width: 380px) {
    flex-flow: row;
    flex: 1 0 50%;
    margin-left: 14rem;
    margin-top: 0;

    align-items: center;

    li {
      margin-bottom: 0;
    }

    
  } */
`;
