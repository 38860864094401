import { string } from 'prop-types';
import React from 'react';
import { SiteHeaderStyled } from './header.style';
import { SocialList } from './social-list';
import { NavMenu } from './NavMenu';
import { Search } from './search';
import { Bio } from './bio';

const Header = props => {
  const { email, twitter } = props;
  return (
    <SiteHeaderStyled>
      <section>
        <div>
          <SocialList email={email} twitter={twitter} fullNavOnly />
          <Search fullNavOnly />
        </div>
        <Bio {...props} />
        <NavMenu />
      </section>
    </SiteHeaderStyled>
  );
};

Header.propTypes = {
  email: string.isRequired,
  twitter: string.isRequired,
};

export default Header;
