import React from 'react';
import { TagList } from './tag-list';
import { FooterStyled } from './footer.styles';

export const Footer = () => (
  <FooterStyled>
    <section>
      <h1>Tags</h1>
      <TagList wrappingLayout={true} />
      <p>
        © {new Date().getFullYear()}, Built with <a href="https://www.gatsbyjs.org">Gatsby</a>
      </p>
    </section>
  </FooterStyled>
);
