import { Link } from 'gatsby';
import { string } from 'prop-types';
import React from 'react';
import headshot from '../images/MarkLennoxSmall.png';
import { BioStyled } from './bio.style';

export function Bio({ author, description, title, byline }) {
  return (
    <BioStyled>
      <Link to="/">
        <h1>{title}</h1>
      </Link>

      <section>
        <div>
          <h2>{author}</h2>
          <h3>{byline}</h3>
        </div>
        <img src={headshot} alt={`${author} ${description}`} />
      </section>
    </BioStyled>
  );
}

Bio.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  byline: string.isRequired,
  author: string.isRequired,
};
