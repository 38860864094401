import React, { Component } from 'react';
import { Index } from 'elasticlunr';
import { Link, StaticQuery, graphql } from 'gatsby';
import { object } from 'prop-types';
import { SearchStyled } from './search.style';

export function Search() {
  return (
    <StaticQuery
      query={graphql`
        query SearchIndexQuery {
          siteSearchIndex {
            index
          }
        }
      `}
      render={data => <SearchComponent searchIndex={data.siteSearchIndex.index} />}
    />
  );
}

Search.propTypes = {};

class SearchComponent extends Component {
  static propTypes = {
    // TODO : what is the _actual_ type needed here
    searchIndex: object.isRequired,
  };

  state = {
    query: ``,
    results: [],
  };

  clearSearch = () => {
    this.setState({ query: '' }, this.search);
  };

  render() {
    return (
      <SearchStyled className={`${this.state.results.length > 0 ? 'expanded' : ''}`} onSubmit={this.handleSubmit}>
        <a className="closeSearch" href="#" onClick={this.clearSearch} aria-label="clear search results">
          x
        </a>
        <input
          tabIndex="0"
          name="query"
          type="text"
          placeholder="Search..."
          value={this.state.query}
          onChange={this.handleKeypress}
        />

        {this.state.results.length > 0 && (
          <section>
            <h1>
              {this.state.results.length} blog posts match &quot;{this.state.query}&quot;
            </h1>
            <ul>
              {this.state.results.map(page => (
                <li key={page.id}>
                  <Link to={'/' + page.slug}>{page.title}</Link>
                </li>
              ))}
            </ul>
          </section>
        )}
      </SearchStyled>
    );
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex);

  handleKeypress = event => {
    this.setState({ query: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.search();
  };

  search = () => {
    // const query = evt.target.value;
    this.index = this.getOrCreateIndex();
    const results = this.index
      .search(this.state.query, {
        // this ensures partial word matches return results
        // otherwise "mus" would not find articles containing word "music"
        expand: true,
      })
      // Map over each ID and return the full document
      .map(({ ref }) => this.index.documentStore.getDoc(ref));
    this.setState({
      // query,
      // Query the index with search string to get an [] of IDs
      results,
    });
  };
}
