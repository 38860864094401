import styled from 'styled-components';

export const FooterStyled = styled.footer`
  display: flex;
  flex-flow: column;
  flex: 0 0 100%;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-shadow: inset 0 0 0 1000px rgba(19, 190, 242, 0.1);
  border-top: 1px solid rgba(19, 190, 242, 0.2);

  & > section {
    width: 100%;
    max-width: 96rem;
    margin: 0 2rem 4rem;

    & > ul {
      li {
        margin-left: 0;
        padding: 0;
      }
    }

    & p:last-child {
      margin-top: 2rem;
    }
  }
`;
