import React from 'react';
import { string, node, bool } from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import { Footer } from './footer';
import styled from 'styled-components';
import '../styles/layout.scss';
import { CommonLayout } from '../styles/common-layout';

export const ContentContainer = styled.main`
  /* ${CommonLayout} */

  display: flex;
  flex-flow: column;
  flex: 0 0 100%;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;

  & > article {
    width: 100%;
    max-width: 96rem;
    margin: 1rem 2rem 4rem;
    padding: 3rem 2rem 0;
  }

  &.wide {
    & > article {
      margin: 1rem 2rem 4rem;
      padding: 1rem 0 0;
    }
  }
`;

const Layout = ({ children, className, isWide }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            byline
            email
            twitter
            author
          }
        }
      }
    `}
    render={data => (
      <>
        <Header {...data.site.siteMetadata} />
        <ContentContainer className={`${className} ${isWide && 'wide'}`}>
          <article>{children}</article>
        </ContentContainer>
        <Footer />
      </>
    )}
  />
);

Layout.propTypes = {
  children: node.isRequired,
  className: string,
  isWide: bool,
};

export default Layout;
