import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { kebabCase } from 'lodash';
import { TagListStyled } from './tag-list.style';
import { bool } from 'prop-types';

export function TagList({ showArticles, wrappingLayout }) {
  return (
    <StaticQuery
      query={graphql`
        {
          allMarkdownRemark(limit: 2000, filter: { frontmatter: { draft: { in: [false, null] } } }) {
            group(field: frontmatter___tags) {
              fieldValue
              totalCount
              nodes {
                excerpt(pruneLength: 120)
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
      render={({ allMarkdownRemark: { group } }) => (
        <TagListStyled className={wrappingLayout ? `wrapped` : `list`}>
          {group.map((tagData, index) => (
            <li key={index}>
              <Link to={`/tags/${kebabCase(tagData.fieldValue)}`}>
                <h2>{tagData.fieldValue}</h2>
              </Link>
              {showArticles && (
                <ul>
                  {tagData.nodes.map((node, index) => (
                    <li key={index}>
                      <Link to={node.fields.slug}>
                        <h3>{node.frontmatter.title}</h3>
                        <p>{node.excerpt}</p>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </TagListStyled>
      )}
    />
  );
}

TagList.propTypes = {
  showArticles: bool,
  wrappingLayout: bool,
};
