import styled from 'styled-components';

export const BioStyled = styled.div`
  /* background: rgb(0, 255, 0, 0.1); */

  display: flex;
  flex-flow: row;
  justify-content: space-between;

  a {
    text-decoration: none;
    text-transform: uppercase;
    align-items: center;
    display: flex;

    h1 {
      color: #e0e0e0;
      font-size: 5rem;
    }

    &:hover h1 {
      color: rgba(242, 19, 104, 1);
    }
  }

  section {
    display: flex;

    img {
      height: 12rem;
      width: 12rem;
      border-radius: 6rem;
      border: 0.25rem solid #101010;
      align-self: flex-start;
    }

    div {
      display: flex;
      flex-flow: column;
      justify-content: center;
      margin-right: 2rem;
      max-width: 18rem;

      h2 {
        font-size: 3rem;
        text-align: center;
      }

      h3 {
        font-size: 1.6rem;
        text-align: center;
        color: rgba(242, 19, 104, 1);
      }
    }

    h2 {
    }
  }

  /* @media only screen and (max-width: 768px) and (min-width: 641px) { */
  @media only screen and (max-width: 768px) {
    flex-flow: column;

    a {
      justify-content: center;
    }

    section {
      justify-content: center;
      margin: 2rem 0;

      img {
        width: 8rem;
        height: 8rem;
      }
    }
  }

  /* @media only screen and (max-width: 640px) and (min-width: 381px) {
  }

  @media only screen and (max-width: 380px) {
  } */
`;
